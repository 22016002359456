@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

@font-face {
  font-family: Metropolis;
  src: url(assets/fonts/Metropolis-Regular.otf);
}

@font-face {
  font-family: MetropolisBold;
  src: url(assets/fonts/Metropolis-Bold.otf);
}
@font-face {
  font-family: MetropolisSemiBold;
  src: url(assets/fonts/Metropolis-SemiBold.otf);
}
@font-face {
  font-family: MetropolisExtraBold;
  src: url(assets/fonts/Metropolis-ExtraBold.otf);
}

* {
  box-sizing: border-box;
}

body {
  background: #72A8DF !important;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family:  Metropolis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #F9F9F9;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #F9F9F9;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.logo_login{
  background: url('assets/logo.png');
  background-size: cover;
  background-position: center bottom;
  height: 70px;
  width: 500px;
  margin-bottom: 25%;
}
.login-background{
  background: url('assets/login-background.jpg');
  background-size: cover;
  background-position: center bottom;
}
.login-background-black{
  margin-left: -15px;
  background-color: rgba(0, 0, 0, 0.5);
}
.login-background-border{
  right: 30px;
  height: 80%;
  bottom: 0px;
}
.login-left-side{
  border-left: #F9F9F9 solid 2px;
}
.login-left-side h1{
  color: white;
  margin: 0px;
  font: Bold 50px/135px Metropolis;
  line-height: 0.5;
}
.login-left-side h2{
  font: 30px/70px MetropolisSemiBold;
  color: #1B4688;
  margin: 0px;
}
.login-right-side{
  color: #F9F9F9;
}
.login-right-side input[type=text],input[type=password],input[type=password]:focus {
  background-color: rgba(255,255,255,0.5) !important;
  border:0;
  text-align: right;
  color: #F9F9F9;
  height: 50px;
  font: Bold 25px/30px MetropolisSemiBold;
}
.login-right-side input[type=text]::placeholder,
input[type=password]::placeholder{
  color: #F9F9F9;
}
.login-right-side button{
  color: #F9F9F9;
  background-color: #72A8DF;
  height: 50px;
  border:0px;
  font: Bold 25px/30px MetropolisSemiBold;
}
.login-right-side h2{
  color: #74B7FA;
  font: bold 35px/42px MetropolisExtraBold;
}
.login-right-side h6{
  margin: 0px;
  color: #F9F9F9;
  font:normal 29px/35px Metropolis;
}
.login-alert{
  position: absolute;
  top: 2em;
  right: 0;
  background-color: #5491DF;
  color: #F1F2F7;
  font-size:normal 29px/35px Metropolis;
  padding: 15px;
  min-width: 400px;
}
.aside{
  width: 80px;
  padding: 0px 10px;
  background-color: #053C5E;
  top: 0;
  left: 0px;
  z-index: 1;
  color: #F9F9F9;
  text-align: center;
}
.aside p{
  border-bottom: 1px solid #F9F9F9;
}
.aside .bookmark{
  background-color: #002F4B;
  border-radius: 5px;
  padding: 10px;
}
.dashboard-container{
  padding: 0px 0px 0px 80px !important;
  background-color: #F1F2F7;
}
header{
  background-color:#F9F9F9;
  height: 50px;
  padding: 10px;
  box-shadow: 0px 2px 3px #ccc;
}
.btn-blue{
  background-color: #053C5E !important;
  color: #F9F9F9 !important;
  cursor: pointer;
}
.btn-white{
  background-color: #F9F9F9 !important;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #ccc;
  color: #38567A !important;
  font-weight: bold !important;
}

.dashboard-container .table{
  background-color: #F9F9F9;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  color: #797979;
}
.dashboard-container .table th{
  border:0;
}
.table-container{
  background-color: #00000059;
  overflow: auto;
  border-radius: 0 0 5px 5px;
  height: calc(100% - 260px);
}
.table-container .table{
  border-radius: 0;
}
.table-container .table td{
  border-color: #CFCFCF;
}
.table-header{
  background-color: #084162;
  color: #F9F9F9;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #424242;
}
.table-header th{
  padding: 10px !important;
}
.table-container h5{
  font-weight: normal;
  color: #F9F9F9;
  font-size: 20px;
  text-align: center;
}

.modal-logs{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-logs-container{
  width: 70%;
  max-width: 900px;
  height: 80%;
  margin: 50px auto;
  padding: 20px;
  background-color: #F9F9F9;
  border-radius: 5px;
  color: #727272;
}
.modal-logs-container .logs{
  height: 70%;
}
.modal-logs-container p{
  margin: 5px 0px;
}
.modal-logs-container h1{
  border-bottom: 1px solid #5491DF;
  color: #5491DF;
  font-size: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.modal-logs-container h6{
  color: #5491DF;
  margin: 0px;
}

.modal-apps{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-apps-container{
  width: 70%;
  height: 80%;
  margin: 50px auto;
  padding: 20px;
  background-color: #F9F9F9;
  border-radius: 5px;
  color: #727272;
}
.modal-apps-container .logs{
  height: 70%;
}
.modal-apps-container p{
  margin: 5px 0px;
}
.modal-apps-container h1{
  border-bottom: 1px solid #5491DF;
  color: #5491DF;
  font-size: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.modal-apps-container h6{
  color: #5491DF;
  margin: 0px;
}


.switch-white{
  width: 50px;
  height: 22px;
  border-radius:20px;
  padding: 2px 3px 0px 3px;
  display: flex;
  background-color: #BEBEBE;
  justify-content: space-between;
  cursor: pointer;
}
.switch-white span{
  width: 18px;
  height: 18px;
  border-radius:20px;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  cursor: pointer;
  background-color: #F9F9F9;
}
.switch-white span:nth-child(2){
  background-color: #bebebe;
  color:#F9F9F9;
}
.switch-white.active{
  background-color: #084162;
}
.switch-white.active span{
  background-color: #084162;
}
.switch-white.active span:nth-child(2){
  background-color: #F9F9F9;
}

.source-app-menu{
  background-color: #F9F9F9;
  border-radius: 5px;
  box-shadow: 0 0 4px #555;
  padding: 1.25em;
  max-width: 900px;
  position: absolute;
  right: 16px;
  top: 120px;
}
.source-app-menu .table{
  border-top: 4px solid #60BF9F !important;
}

.form-search-container{
  border-bottom: 1px solid #5491DF !important;
}
.form-search, .form-search:focus{
  border:0 !important;
  box-shadow: none !important;
  color: #5491DF !important;
  background-color: transparent !important;
}
.form-search::placeholder{
  color: #5491DF !important;
}

.modal-logguer{
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
}

.block-element{
  cursor: not-allowed;
  pointer-events: none;
}

.pointer{
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6{
  font-family: MetropolisBold;
}

.message-alert{
  position: absolute !important;
  right: 1rem;
  top: 2rem;
  z-index: 2;
}